
import { RouteNames } from '@/router/names'
import { IProduct } from '@/shared/types/match'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FFCustomerRequestCard',
  props: {
    product: {
      type: Object as PropType<IProduct>
    }
  },
  methods: {
    goBatch () {
      alert('go batches')
    },
    async openDetail (productId: string) {
      await this.$router.push(
        {
          name: RouteNames.FF_BOM_DETAIL,
          params: { product_id: productId }
        }
      )
    }
  }
})
