
import Vue from 'vue'
import { KwikMediumContainer } from 'kwik-vue'
import { readCurrentMatch } from '@/modules/match/store/getters'
import { IMatch, IProduct } from '@/shared/types/match'
import FFProductCard from '../components/FFProductCard.vue'

export default Vue.extend({
  name: 'FFBillOfMaterials',
  components: { KwikMediumContainer, FFProductCard },
  data () {
    return {
      loading: true,
      products: [] as IProduct[],
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Distinta base', disabled: false }
      ]
    }
  },
  computed: {
    currentMatch () {
      return readCurrentMatch(this.$store)
    }
  },
  methods: {
    loadProducts (match: IMatch | null) {
      if (!match) return null
      // TODO usa il primo universo non quello selezionato
      this.products = match.activeUniverses[0].products
    }
  },
  watch: {
    currentMatch: {
      handler (match: IMatch | null) {
        this.loadProducts(match)
      }
    }
  },
  async mounted () {
    const match = readCurrentMatch(this.$store)
    this.loadProducts(match)
  }
})
